import { SEARCH_FIND_WITH_PRICE_PROFILE } from "./../../../constants/common";
import { isEmpty } from "lodash-es";
/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
//Standard libraries
import { call, put } from "redux-saga/effects";
//Foundation libraries
import productsService from "../../../_foundation/apis/search/products.service";
//Redux
import * as ACTIONS from "../../action-types/catalog";

/**
 * Saga worker to invoke get product list API
 */
export function* fetchProductList(action: any) {
  try {
    const payload = action.payload;

    //On page refresh offset, limit and orderBy are present at parameters level without being present at query level
    //below code is accounting for that
    const { parameters } = payload;
    const query = parameters?.query;
    const updatedQuery = query ? { ...query } : {};
    const { offset, orderBy, limit } = parameters;
    if (offset !== undefined && !updatedQuery.offset) {
      updatedQuery["offset"] = offset;
    }
    if (!isEmpty(orderBy) && !updatedQuery.orderBy) {
      updatedQuery["orderBy"] = orderBy;
    }
    if (limit !== undefined && !updatedQuery.limit) {
      updatedQuery["limit"] = limit;
    }
    let response = yield call(productsService.findProductsUsingGET, { ...payload.parameters, query: updatedQuery });
    // On page refresh logic ends for offset, limit and orderBy

    // If using search and yielding no results, try again with a price profile
    if (response.data?.total === 0 && payload.parameters.searchTerm?.length > 0) {
      updatedQuery["profileName"] = SEARCH_FIND_WITH_PRICE_PROFILE;
      response = yield call(productsService.findProductsUsingGET, {
        ...payload.parameters,
        query: { ...updatedQuery },
      });
    }

    yield put({
      type: ACTIONS.PRODUCT_LIST_GET_SUCCESS,
      response: response.data,
      payload: payload,
    });
  } catch (error) {
    yield put({ type: ACTIONS.PRODUCT_LIST_GET_ERROR, error });
  }
}

/**
 * Saga worker to invoke get product list API for PDP
 */
export function* fetchProductListForPDP(action: any) {
  try {
    const payload = action.payload;
    const response = yield call(productsService.findProductsUsingGET, payload.parameters);
    yield put({
      type: ACTIONS.PRODUCT_LIST_FOR_PDP_GET_SUCCESS,
      response: response.data,
      payload: payload,
    });
  } catch (error) {
    yield put({ type: ACTIONS.PRODUCT_LIST_FOR_PDP_GET_ERROR, error });
  }
}

export function* fetchProductListDetails(action: any) {
  try {
    const payload = action.payload;
    const params = payload.parameters;
    const r = yield call(productsService.findProductsUsingGET, params);
    const response = r.data;
    yield put({ type: ACTIONS.PRODUCT_LIST_DEETS_S, response, payload });
  } catch (error) {
    yield put({ type: ACTIONS.PRODUCT_LIST_DEETS_F, error });
  }
}
